
h1, h2, h3 {
    color: #333;
}

.faq-section, .contact-section, .support-form-section {
    margin-bottom: 40px;
}

.faq-item {
    margin-bottom: 20px;
}

.faq-question {
    cursor: pointer;
    color: #007bff;
}

.faq-answer {
    padding-left: 20px;
    color: #555;
}

.contact-section p {
    line-height: 1.6;
}

.support-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 10px;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.form-group input, .form-group textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.error {
    color: red;
    font-size: 12px;
}

.submit-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0056b3;
}
